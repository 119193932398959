import algoliaSearch from 'algoliasearch';
import algoliasearchHelper from 'algoliasearch-helper';

const client = algoliaSearch('4RZR8B2FK9', '0341a6b88fc35459d3a4c1331e9b6f65');

export const searchHelper = algoliasearchHelper(client, 'questions', {
  facets: ['collection', 'rank', 'series', 'procedures', 'tags'],
});

export const exportHelper = algoliasearchHelper(client, 'questions', {
  hitsPerPage: 500,
  disjunctiveFacets: ['rank', 'series', 'procedures', 'tags'],
  facets: ['collection'],
});
